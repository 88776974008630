import TYPES from '@/types';

import { currencyFormat } from '@/vue-app/utils/currency';

// Application
import GetCustomerPersonalityProfileQuery
  from '@/modules/flagship/customer-investor-profiling/application/query/get-customer-personality-profile-query';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import { DateFormatter } from '@/modules/shared/domain/date-formatters';

type Option = {
  label: string;
  value: string;
  icon: string;
}

type ProfileInfo = {
  label: string;
  description_1: string;
  description_2: string;
}

export default class UserProfilePersonalityProfileTabViewModel {
  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  @Inject(TYPES.GET_CUSTOMER_PERSONALITY_PROFILE_QUERY)
  private readonly get_customer_personality_profile!: GetCustomerPersonalityProfileQuery;

  @Inject(TYPES.DATE_FORMATTER)
  readonly date_formatter!: DateFormatter;

  @Inject(TYPES.NOTIFIER)
  readonly messageNotifier!: MessageNotifier;

  readonly i18n_namespace = 'components.user-profile.user-profile-tabs.user-profile-personality-tab';

  is_loading = false;

  full_name = '';

  age = 0;

  profile_info: ProfileInfo = {
    label: '',
    description_1: '',
    description_2: '',
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  information_items: Array<Option> = [];

  pluralize = (count: number, noun: string, suffix = 's') => `${count} ${noun}${count !== 1 ? suffix : ''}`;

  getProfileInfo = (profile: string): ProfileInfo => {
    switch (profile) {
      case '1': return {
        label: `${this.translate('investor_profiles.1')}\n${this.translate('risk')}`,
        description_1: this.translate('description_investor_profiles.1.description_1'),
        description_2: this.translate('description_investor_profiles.1.description_2'),
      };
      case '2': return {
        label: `${this.translate('investor_profiles.2')}\n${this.translate('risk')}`,
        description_1: this.translate('description_investor_profiles.2.description_1'),
        description_2: this.translate('description_investor_profiles.2.description_2'),
      };
      case '3': return {
        label: `${this.translate('investor_profiles.3')}\n${this.translate('risk')}`,
        description_1: this.translate('description_investor_profiles.3.description_1'),
        description_2: this.translate('description_investor_profiles.3.description_2'),
      };
      default: return {
        label: '',
        description_1: '',
        description_2: '',
      };
    }
  }

  loadPersonalityProfile = async () => {
    try {
      const personality_profile = await this.get_customer_personality_profile.execute();
      this.full_name = personality_profile.full_name;
      this.age = personality_profile.age;
      if (personality_profile.investor_profile_completed) {
        let dependents = '';
        if (personality_profile.child_dependent_count) {
          dependents = `${dependents}${this.pluralize(personality_profile.child_dependent_count, this.translate('child'), 'es')}`;
        }
        if (personality_profile.elder_dependent_count) {
          if (personality_profile.child_dependent_count) {
            dependents = `${dependents}\n`;
          }
          dependents = `${dependents}${this.pluralize(personality_profile.elder_dependent_count, this.translate('elder'))}`;
        }
        if (personality_profile.pet_dependent_count) {
          // eslint-disable-next-line max-len
          if (personality_profile.elder_dependent_count || personality_profile.child_dependent_count) {
            dependents = `${dependents}\n`;
          }
          dependents = `${dependents}${this.pluralize(personality_profile.pet_dependent_count, this.translate('pet'))}`;
        }
        this.information_items.push(
          { label: this.translate('economic_dependents'), value: dependents, icon: '' },
        );
        this.information_items.push(
          {
            label: this.translate('employment_situation'),
            value: personality_profile.employment_situation,
            icon: '',
          },
        );
        this.information_items.push(
          {
            label: this.translate('behavior_of_my_income'),
            value: personality_profile.income_behavior_in_following_year,
            icon: '',
          },
        );
        this.information_items.push(
          {
            label: this.translate('monthly_savings_capacity'),
            value: currencyFormat(personality_profile.monthly_savings_capacity),
            icon: '',
          },
        );
      }
      if (personality_profile.has_allianz_account) {
        this.information_items.push(
          {
            label: this.translate('education_level'),
            value: personality_profile.education_level,
            icon: '',
          },
        );
        this.information_items.push(
          {
            label: this.translate('investment_experience'),
            value: personality_profile.investment_experiences,
            icon: '',
          },
        );
      }
      this.information_items.push({
        label: this.translate('last_updated_date'),
        value: this.date_formatter.formatDate(personality_profile.updated_at, 'DD MMM YYYY'),
        icon: '',
      });
      if (personality_profile.personality_profile) {
        this.profile_info = this.getProfileInfo(personality_profile.personality_profile);
        this.information_items.push({
          label: this.translate('investor_profile'),
          value: this.profile_info.label,
          icon: 'mdi-chevron-down',
        });
      }
    } catch {
      this.messageNotifier.showErrorNotification(this.translate('errors.load_personality_profile'));
    }
  }

  showInfo = (index: number) => {
    const item = this.information_items[index];
    if (item.icon === 'mdi-chevron-up') {
      item.icon = 'mdi-chevron-down';
    } else if (item.icon === 'mdi-chevron-down') {
      item.icon = 'mdi-chevron-up';
    }
  }

  initialize = async () => {
    this.is_loading = true;
    await this.loadPersonalityProfile();
    this.is_loading = false;
  }
}
